import React from 'react'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import Layout from '../components/layout'
import SEO from '../components/seo'

import '../components/about.scss'

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="subheader-img-about" />
    <h1 className="about-page-title">About Us</h1>
    <div className="about-page-wrapper">
      <h2>Our Philosophy</h2>
      <p className="about-text">
        Premier Legislative Consulting (PLC Texas) believes that the democratic
        process provides fantastic opportunities. PLC Texas acts within that
        process with a full array of services to help meet the legislative goals
        of its clients in a highly effective manner while meeting the highest
        ethical standards.
      </p>
      <h2>Our Background</h2>
      <p className="about-text">
        PLC Texas is an Austin-based government affairs firm that provides a
        wide variety of services to companies, associations, and individuals
        seeking to influence the development of public policy in Texas. PLC
        Texas can help you navigate official government offices and help build
        your brand with officeholders, policy leaders, Texas state agencies and
        beyond.
      </p>
      <p className="about-text">
        PLC Texas was founded in 2010 to provide our clients with successful,
        experienced, and ethical government-relations services across the Texas
        government. Our staff has over 60 years of legislative, public policy,
        and political experience. We approach everything in a bipartisan and
        consensus-building manner. We have worked at the highest level of state
        government, affecting legislators, staff, legislative committees,
        regulatory agencies and private sector thought-leaders.
      </p>
      <p className="about-text">
        We strive to provide our clients with a customized government affairs
        plan that will not only positively affect public policy in Texas but
        will also establish our clients among the most influential experts in
        their fields.
      </p>
      <section className="about-bios">
        <div className="about-bio-wrapper">
          <div className="about-heading-wrapper">
            <div className="about-heading-img-wrapper">
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1604622275/plctexas/John_Hubbard.png"
                alt="John Hubbard"
              />
            </div>

            <div className="about-heading-text-wrapper">
              <h2>D. John Hubbard</h2>
              <p className="about-heading-href-wrapper phone-wrap">
                <a href="tel:1-512-653-6428" className="about-heading-phone">
                  <FaPhone className="footer-phone-icon" />
                  512-653-6428
                </a>
              </p>
              <p className="about-heading-href-wrapper">
                <a href="mailto:john@plctexas.com">
                  <FaEnvelope className="footer-email-icon" />
                  john@plctexas.com
                </a>
              </p>
            </div>
          </div>

          <p className="about-text">
            John Hubbard has 30 years of experience in the public policy arena.
            He began his career in public policy and politics at the Texas
            Sunset Commission, a standing joint committee of the legislature
            which makes recommendations on whether to continue operating state
            agencies, and if so, how to improve their functions. The Sunset
            process in Texas has abolished 41 agencies, consolidated another 51,
            and had an estimated positive fiscal impact of $1 billion.
          </p>
          <p className="about-text">
            John then went on to become a legislative advisor to State Senator
            David Cain. While working for Senator Cain, John was responsible for
            advising the Senator on a number of policy areas including economic
            development, public utilities, alternative energy, taxes,
            environmental protection, the state budget, education, and health &
            human services. He developed effective bi-partisan
            consensus-building strategies to ensure passage of the Senator’s
            legislative initiatives in these areas. He also served as the
            Senator’s senior speechwriter.
          </p>
          <p className="about-text">
            In 2003, John began his career as an independent lobbyist and public
            policy consultant. He is the founding partner of Premier Legislative
            Consulting. His efforts on behalf of his clients have resulted in
            them being recognized among the most influential at the state
            capitol by Mike Hailey’s Capitol Inside.
          </p>
          <p className="about-text">
            John was first exposed to public policy while earning a Bachelor’s
            Degree in Economics from the University of Texas at Austin, before
            going on to receive his Master’s Degree in Public Affairs from the
            Paul H. O’Neill School of Public and Environmental Affairs at
            Indiana University. He completed his graduate internship at the
            prestigious l’Ecole Nationale d’Administration (National School of
            Administration) in Paris, France. John was raised in New York and
            Texas by his British parents. He enjoys spending time with his
            daughter as well as soccer, cycling, chess, and photography.
          </p>
        </div>
        <div className="about-bio-wrapper">
          <div className="about-heading-wrapper">
            <div className="about-heading-img-wrapper">
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1604622275/plctexas/Ian_Randolph.png"
                alt="Ian Randolph"
              />
            </div>

            <div className="about-heading-text-wrapper">
              <h2>Ian Randolph</h2>
              <p className="about-heading-href-wrapper phone-wrap">
                <a href="tel:1-512-632-4833" className="about-heading-phone">
                  <FaPhone className="footer-phone-icon" />
                  512-632-4833
                </a>
              </p>
              <p className="about-heading-href-wrapper">
                <a href="mailto:ian@plctexas.com">
                  <FaEnvelope className="footer-email-icon" />
                  ian@plctexas.com
                </a>
              </p>
            </div>
          </div>
          <p className="about-text">
            Ian is a highly respected and trusted fixture at the Texas Capitol
            with over 30 years of experience working in both the House of
            Representatives and the Senate. He is a long-time legislative and
            political advisor, most recently serving as Chief of Staff for
            Senator Eddie Lucio, Jr. of Brownsville. Before that, Ian worked for
            Senators David Cain of Dallas and Ted Lyon of Rockwall. During his
            career, Ian has worked for members in both Chambers, all of whom
            have been chairs of major standing or special committees.
          </p>
          <p className="about-text">
            Throughout his time in public service, Ian has held almost every
            possible legislative policy position, from Policy Analyst, Committee
            Analyst, Legislative Aide, Committee Director, and Legislative
            Director, to Chief of Staff. He has working experience with all
            aspects of the legislative process from appropriations to
            parliamentary procedures. Additionally, Ian has worked on thousands
            of bills covering nearly every major issue facing Texas.
          </p>
          <p className="about-text">
            As a result, he has developed a superior understanding of the
            legislative process and earned a reputation for his bi-partisan
            results-orientated approach, effective policy work, integrity, and
            thorough knowledge of parliamentary procedures. He also has
            extensive communications experience in press relations and speech
            writing.
          </p>
          <p className="about-text">
            While having deep Texas family roots, the son of a U.S. Air Force
            Colonel, Ian was born in Paris, France, and raised in Ireland, the
            United Kingdom, and Spain. Ian is married with one child. He
            developed his passion for policymaking while studying Government at
            the University of Texas.
          </p>
          <p className="about-text">
            Ian joined Premier Legislative Consulting in 2012.
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default AboutPage
